'use client'

import Link from 'next/link'
import { ReactNode } from 'react'
import { Slug } from 'sanity'
import { Category } from '~/lib/sanity.queries'
import { cn } from '~/utils'

export function Pill(props: {
  href: string
  children: ReactNode
  className?: string
  active?: boolean
}) {
  return (
    <Link
      className={cn(
        'inline-flex text-sm tracking-wider py-2 px-3 bg-foreground/10 rounded-sm w-fit hover:scale-105 hover:bg-primary-20 transition-transform  gap-2 items-center',
        { 'bg-primary-60': props.active },
        props.className,
      )}
      href={props.href}
    >
      {props.children}
    </Link>
  )
}

export function CategoryPill(props: { slug: Slug; title: string; active?: boolean }) {
  return (
    <Pill href={`/learn/${props.slug.current}`} active={props.active}>
      {props.title}
    </Pill>
  )
}

export function PostCategories(props: { categories?: Category[] }) {
  return (
    <div className="flex flex-wrap gap-2 flex-shrink-0">
      {props.categories?.map(({ title, slug }, key) => (
        <CategoryPill key={key} slug={slug} title={title} />
      ))}
    </div>
  )
}
